<template>
  <div class='lg:mt-12'>    
    <div class='flex flex-col lg:flex-row justify-between gap-x-6 gap-y-4 lg:mt-12'>
      <vod-controls class='w-full lg:w-48 flex-grow-0 flex-shrink-0 px-2 lg:px-0' />
      <div class='flex-grow text-sm text-gray-600 flex flex-col gap-y-4 justify-start'>
        <vod-row
          v-for='vod in filteredVods'
          :key='`vod-${vod.id}`'
          :vod='vod'
          class='cursor-pointer opacity-100 lg:opacity-75 hover:opacity-100 hover:shadow-md lg:rounded-lg'
          @click.native='goToSingleVod(vod.id)' />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VodRow                     from '@/components/vods/VodRow.vue'
import VodControls                from '@/components/vods/VodControls.vue'

export default {
  name: 'Vods',
  components: {
    VodRow,
    VodControls,
  },
  computed: {
    ...mapGetters('vods', [
      'filteredVods'
    ])
  },
  methods: {
    ...mapActions([
      'hideFullpageLoadingIndicator',
      'showFullpageLoadingIndicator',
    ]),
    ...mapActions('vods', [
      'getVods'
    ]),
    ...mapActions('filters', [
      'getFilters'
    ]),
    goToSingleVod (vodId) {
      this.$router.push({name: 'Vod', query: { vod_id: vodId }})
    }
  },
  beforeDestroy () {
    this.hideFullpageLoadingIndicator()
  },
  mounted () {
    this.showFullpageLoadingIndicator()
    this.getVods().then(() => {
      this.hideFullpageLoadingIndicator()
    })
    this.getFilters()
  }
}
</script>
