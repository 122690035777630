<template>
  <div class='mt-4'>
    <h2 class='uppercase font-semibold'>{{singleFilter.name}}</h2>
    <label v-for="(keyword, index) in singleFilter.list"
      :key='`${singleFilter.name}-${keyword}-${index}`'
      class='block py-1 text-sm text-gray-500 flex flex-row items-center cursor-pointer hover:bg-blue-50'>
      <input type="checkbox" class='mr-2'
             :value='keyword'
             v-model='componentFilterValues'>
      <span>{{ keyword }}</span>
    </label>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'VodSingleFilter',
  props: ['singleFilter'],
  data () {
    return {
      componentFilterValues: []
    }
  },
  watch: {
    componentFilterValues: {
      handler: function (newVal) {
        this.checkVodFilter({name: this.singleFilter.name, list: newVal})
      }
    }
  },
  methods: {
    ...mapMutations('vods', [
      'checkVodFilter'
    ])
  }
}
</script>
