<template>
  <div class='flex flex-row lg:flex-col justify-start  border-b lg:border-none'>
    <div class='relative flex-grow lg:flex-grow-0'>
      <input 
        type='text'
        class='w-full px-2 py-3 border-r lg:border border-gray-300 lg:rounded-lg'
        placeholder='Search'
        v-model='searchString'
        @keyup.enter='getSearchedVods' />
      <button
        v-if='searchString'
        @click='resetSearchString'>
        <x-icon 
          class='absolute text-gray-300 h-5'
          style='top: 0.875rem; right: 0.5rem;' />
      </button>
      <search-icon
        v-else
        class='absolute text-gray-300 h-5'
        style='top: 0.875rem; right: 0.5rem;' />
    </div>
    <vod-filters />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { SearchIcon, XIcon } from '@vue-hero-icons/outline'
import VodFilters     from '@/components/vods/VodFilters.vue'

export default {
  name: 'VodControls',
  components: {
    VodFilters,
    SearchIcon,
    XIcon
  },
  data () {
    return {
      searchString: '',
    }
  },
  methods: {
    ...mapActions('vods', [
      'getVods'
    ]),
    getSearchedVods () {
      this.getVods(this.searchString)
    },
    resetSearchString () {
      this.searchString = ''
      this.getSearchedVods()
    }
  }
}
</script>
