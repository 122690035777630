<template>
  <div>
    <button 
      class='block lg:hidden px-2 py-3'
      @click='toggleShowFilters'>
      <adjustments-icon />
    </button>
    <div 
      class='filters z-10 bg-white w-full border lg:border-none p-4 lg:p-0 shadow-md lg:shadow-none'
      :style='filterPosition'>
      <h2 class='text-2xl text-gray-700 uppercase font-semibold mb-6 lg:hidden'>
        Filters
      </h2>
      <vod-single-filter
        v-for='filter in filterList'
        :key='`filter-${filter.name}`'
        :single-filter='filter' />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { AdjustmentsIcon } from '@vue-hero-icons/outline'
import VodSingleFilter from '@/components/vods/VodSingleFilter.vue'

export default {
  name: 'VodFilters',
  components: {
    AdjustmentsIcon,
    VodSingleFilter,
  },
  data () {
    return {
      showContentFilter: false,
    }
  },
  watch: {
    filterList: {
      handler: function (newVal) {
        if (newVal) {
          this.setInitialSelectedVodFilters(newVal)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('filters', [
      'showingContentsFilter'
    ]),
    filterList () {
      return this.showingContentsFilter('vod').keywords
    },
    filterPosition () {
      return (this.showContentFilter) ? 'top:40vh' : 'top:100vh'
    }
  },
  methods: {
    ...mapMutations('vods', [
      'setInitialSelectedVodFilters',
    ]),
    toggleShowFilters () {
      this.showContentFilter = !this.showContentFilter
    }
  }
}
</script>

<style lang='scss' scoped>
.filters {
  @apply overflow-y-auto;
  transition: all 0.15s ease-in-out;
  position: fixed;
  left: 0;
  height: 60vh;
  top: 100vh;
}

 @media (min-width: 1024px) { 
  .filters {
    position: static;
    height: auto;
  }
 }
 </style>
