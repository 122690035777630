<template>
  <div class='flex flex-col lg:flex-row border-b lg:border'>
    <img 
      :src='vod.thumbnailUrl'
      class='w-full lg:w-2/5 flex-grow-0 flex-shrink-0 lg:rounded-l-lg lg:border-r' />
    <div class='px-2 lg:px-4 pt-2 lg:pt-4 pb-4 lg:pb-4 lg:flex-grow'>
      <h2 class='w-3/4 font-semibold uppercase text-lg text-gray-800'>{{vod.title}}</h2>
      <h3 class='font-thin text-gray-600'>{{tagsString}}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VodRow',
  props: ['vod'],
  computed: {
    tagsString () {
      if (this.vod && this.vod.conferenceTags) {
        let tagList =  this.vod.conferenceTags.map(tag => {
          return tag.list
        }).flat()
        return tagList.map(tag => {
          return `#${tag}`
        }).join(' ')
      } else {
        return ''
      }
    },
  },
  methods: {

  }
}
</script>
